import { OutputSelector } from "reselect";

import { IApplicationState } from "../rootInterface";

export enum EModals {
  alertDrawer = "alertDrawer",
  activateServiceModal = "activateService",
  assetModal = "assetModal",
  dashboardSymbolsSetSequence = "dashboardSymbolsSetSequence",
  documentCreateModal = "documentCreateModal",
  documentSetCountry = "documentSetCountry",
  incomingOperation = "incomingOperation",
  transactionEditNote = "transactionEditNote",
  transactionEditTag = "transactionEditTag",
  transactionAdd = "transactionAdd",
  amlChecksFilter = "amlChecksFilter",
  amlCheckTransfer = "amlCheckTransfer",
  amlCheckAddress = "amlCheckAddress",
  activatePricing = "activatePricing",
  pricingTariffUpgrade = "pricingTariffUpgrade", // Апгрейд тарифа
  pricingSelectTariffForNextRenewal = "pricingSelectTariffForNextRenewal", // Выбор тарифа для следующего продления
  pricingTariffPrepayment = "pricingTariffPrepayment", // Предоплата тарифа
  pricingReplacingTariffWithReplaceCurrent =
    "pricingReplacingTariffWithReplaceCurrent", // Замена тарифа с фризом текущего
  planAndPaymentUnsubscribe = "planAndPaymentUnsubscribe",
  planAndPaymentPreOrderDelete = "planAndPaymentPreOrderDelete",
  planAndPaymentPrePurchaseHistory = "planAndPaymentPrePurchaseHistory",
  planAndPaymentDeletePaymentMethod = "planAndPaymentDeletePaymentMethod",
  amlAddWallet = "amlAddWallet",
  amlCheckConfirm = "amlCheckConfirm",
  amlCheckOver = "amlCheckOver",
  upgradePlan = "upgradePlan",
  settingsNewPaymentMethod = "settingsNewPaymentMethod",
  amlActivate = "amlActivate",
  shareLink = "shareLink",
  deleteReport = "deleteReport",
  continueCreateReport = "continueCreateReport",
  cancelCreateReport = "cancelCreateReport",
  publicWalletDisable = "publicWalletDisable",
  apiKeyCreate = "apiKeyCreate",
  apiKeyEdit = "apiKeyEdit",
  apiKeyDelete = "apiKeyDelete",
  amlRiskModelCreate = "amlRiskModelCreate",
  amlRiskModelDelete = "amlRiskModelDelete",
  amlCheckModelDelete = "amlCheckModelDelete",
  amlRiskModelActivate = "amlRiskModelActivate",
  amlRiskModelUnavailableAdd = "amlRiskModelUnavailableAdd",
  riskModelEdit = "riskModelEdit",
  riskModelResetChanges = "riskModelResetChanges",
  reportCreate = "reportCreate",
  reportSuccess = "reportSuccess",
  reportCancel = "reportCancel",
  reportDraft = "reportDraft",
  reportStepTwoUnconfirmedOperation = "reportStepTwoUnconfirmedOperation",
}

export type ToggleModalActionPayload = {
  modal: EModals,
  visible: boolean
}
export type ModalData<T> = T | unknown

export type TModalsStoreState = {[key in EModals]?: boolean};

export type TModalsOutputSelector<T> = OutputSelector<IApplicationState, T, (s: TModalsStoreState) => T>;
