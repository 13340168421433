import { all, call, getContext, put, select, takeLatest } from "redux-saga/effects";
import { AxiosResponse,AxiosError } from "axios";
import request from "API";
import { SagaIterator } from 'redux-saga';

import { paths } from "config/paths";

import delay from "utils/delay";
import { getRiskGradientColor } from "utils/colorGradient/RiskGradient";
import hexToRgb from "utils/hexToRgb";

import { IPayloadAction } from "../rootInterface";
import { getUserInfoDataSelector } from "../user/selectors";
import { getUserInfoSuccess } from "../user/reducers";
import { TUserInfoData } from "../user/types";

import {
  TExplorerFindAddressOrTransaction,
  TFindAddressOrTransactionSOptions,
  TAmlChecksCheckAddress,
  TAmlChecksCheckAddressOptions,
  TAmlChecksChecksRiskOptions,
  TAmlChecksCheckTransfer,
  TAmlChecksCheckTransferOptions,
  TAmlChecksData,
  TAmlChecksDetails,
  TAmlChecksDetailsOptions,
  TAmlChecksFilter,
  TAmlChecksFilterKeys,
  TAmlChecksOptions,
  TAmlChecksRisksData,
  TNetwork
} from "./types";
import {
  amlChecksCheckAddressFailure,
  amlChecksCheckAddressRequest,
  amlChecksCheckAddressSuccess,
  amlChecksCheckTransferFailure,
  amlChecksCheckTransferRequest,
  amlChecksCheckTransferSuccess,
  amlChecksDetailsRequest,
  amlChecksDetailsSuccess,
  amlChecksFailure,
  amlChecksRecheckFailure,
  amlChecksRecheckRequest,
  amlChecksRecheckSuccess,
  amlChecksRequest,
  amlChecksRisksFailure,
  amlChecksRisksRequest,
  amlChecksRisksSuccess,
  amlChecksDetailsFailure,
  amlChecksSuccess,
  getAmlGroupsSuccess, getAmlGroupsRequest, getAmlGroupsFailure,
  networksSuccess,
  networksFailure,
  networksRequest,
  explorerFindAddressOrTransactionSuccess,
  explorerFindAddressOrTransactionFailure,
  amlChecksDetailDeleteSuccess,
  explorerFindAddressOrTransactionRequest, amlChecksDetailDeleteRequest, amlChecksDetailDeleteFailure,
} from "./reducers";

export enum EExposureStatus {
  not_checked = "not_checked",
  error = "error",
  checked = "checked",
  checking = "checking",
  not_supported = "not_supported",
  not_available = "not_available",
}

export enum ECounterpartyStatus {
  not_checked = "not_checked",
  checking = "checking",
  checked = "checked",
  error = "error",
  not_supported = "not_supported",
  not_available = "not_available",
}

export type TPaginationOptions = {
  page?: number
  size?: number
  limit?: number
  offset?: number
}

const amlChecksParams = (
  filter: TAmlChecksFilter|undefined,
  pagination: TPaginationOptions|undefined
) => {
  const params = new URLSearchParams();

  if (filter?.type) params.set("type", String(filter?.type));
  if (filter?.network) params.set("network", String(filter?.network));
  if (filter?.date_from) params.set("created_from", String(filter?.[TAmlChecksFilterKeys.date_from]));
  if (filter?.date_to) params.set("created_to", String(filter?.[TAmlChecksFilterKeys.date_to]));
  if (filter?.search) params.set("search", String(filter?.search));
  if (filter?.group) params.set("group", String(filter?.group));
  if (pagination?.size) params.set("size", String(pagination?.size));
  if (pagination?.page) params.set("page", String(pagination?.page));

  return params;
};

function* _amlChecksRequest(action: IPayloadAction<TAmlChecksOptions>) {
  const params = amlChecksParams(action.payload.filter, action.payload.pagination);
  try {
    const response: AxiosResponse<TAmlChecksData> = yield call(request.get, "/aml-v2/checks/", { params });
    yield put(amlChecksSuccess({ data: response.data, infiniteScroll: action.payload.infiniteScroll }));
    return response;
  } catch (error) {
    yield put(amlChecksFailure(error));
  }
}

function* getAmlGroups() {
  try {
    const response: AxiosResponse<TAmlChecksData> = yield call(request.get, "/aml-v2/checks/groups");
    yield put(getAmlGroupsSuccess(response.data));
    return response;
  } catch (error) {
    yield put(getAmlGroupsFailure(error));
  }
}

function* _amlChecksRequestRecheck(action: IPayloadAction<TAmlChecksOptions>): unknown {
  yield delay(5000);

  const { pathname } = location;
  const response: AxiosResponse<TAmlChecksData> = yield call(_amlChecksRequest, action);
  const recheckRequired = response.data.results.some(check => check.status === 'checking');

  if (recheckRequired && pathname === paths.AML_CHEKS) {
    yield call(_amlChecksRequestRecheck, action);
  }
}

function* amlChecks(action: IPayloadAction<TAmlChecksOptions>) {
  const response: AxiosResponse<TAmlChecksData> = yield call(_amlChecksRequest, action);

  const recheckRequired = response.data.results.some(check => check.status === 'checking');
  if (recheckRequired) {
    yield call(_amlChecksRequestRecheck, action);
  }
}

function* decrementUserAmlCheckAvailable() {
  const userData: TUserInfoData = yield select(getUserInfoDataSelector);
  if (!!userData.aml_checks_available) {
    yield put(getUserInfoSuccess({ ...userData, aml_checks_available: userData.aml_checks_available - 1 }));
  }
}

function* amlChecksCheckTransfer(action: IPayloadAction<TAmlChecksCheckTransferOptions>): any {
  try {
    const response: AxiosResponse<TAmlChecksCheckTransfer> = yield call(request.post,
      "/aml-v2/checks/check-transfer/", action.payload.body);
    yield put(amlChecksCheckTransferSuccess(response.data));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess(response.data.id);
    yield call(decrementUserAmlCheckAvailable);
  } catch (error) {
    yield put(amlChecksCheckTransferFailure(error));
  }
}

function* amlChecksCheckAddress(action: IPayloadAction<TAmlChecksCheckAddressOptions>) {
  try {
    const response: AxiosResponse<TAmlChecksCheckAddress> = yield call(request.post,
      "/aml-v2/checks/check-address/", action.payload.body);
    yield put(amlChecksCheckAddressSuccess(response.data));
    yield call(decrementUserAmlCheckAvailable);
    if (action.payload.callOnSuccess) action.payload.callOnSuccess(response.data.id);
  } catch (error) {
    yield put(amlChecksCheckAddressFailure(error));
  }
}

function* amlChecksRisks(action: IPayloadAction<TAmlChecksChecksRiskOptions>) {
  try {
    const url = `${action.payload.isReport ? '/public/aml-checks/' : '/aml-v2/checks/'}${action.payload.id}/risks/`;
    const response: AxiosResponse<TAmlChecksRisksData> = yield call(request.get, url);
    yield put(amlChecksRisksSuccess(response.data));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess(response.data);
  } catch (error) {
    yield put(amlChecksRisksFailure(error));
  }
}

function* networks(action: IPayloadAction<{search?: string}>) {
  try {
    const response: AxiosResponse<TNetwork[]> = yield call(request.get, "/aml-v2/checks/networks/", {
      params: { search: action.payload.search }
    });
    yield put(networksSuccess(response.data));
  } catch (error) {
    yield put(networksFailure(error));
  }
}

function* amlChecksRecheck(action: IPayloadAction<{ id: string}>) {
  try {
    const response: AxiosResponse<unknown> = yield call(request.post,
      `/aml-v2/${action.payload.id}/recheck/`);

    yield put(amlChecksRecheckSuccess(response.data));
    yield put(amlChecksDetailsRequest({ id: action.payload.id }));
  } catch (error) {
    yield put(amlChecksRecheckFailure(error));
  }
}

function* explorerFindAddressOrTransaction(action: IPayloadAction<TFindAddressOrTransactionSOptions>) {
  try {
    const response: AxiosResponse<TExplorerFindAddressOrTransaction> =
      yield call(request.post, "/aml-v2/find-address-or-transaction/", action.payload.body);
    yield put(explorerFindAddressOrTransactionSuccess(response.data));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess(response.data);
  } catch (error) {
    yield put(explorerFindAddressOrTransactionFailure(error));
  }
}

function* amlChecksDetailDeleteSaga(action: IPayloadAction<{ id: string, callOnSuccess: () => void }>) {
  try {
    const { id } = action.payload;
    yield call(request.delete, `/aml-v2/checks/${id}/delete/`);
    yield put(amlChecksDetailDeleteSuccess());
    if (action.payload.callOnSuccess) action.payload.callOnSuccess();
  } catch (error) {
    yield put(amlChecksDetailDeleteFailure(error));
  }
}

function* amlChecksDetails(action: IPayloadAction<TAmlChecksDetailsOptions>): SagaIterator {
  try {
    const url = `${action.payload.isReport ? '/public/aml-checks/' : '/aml-v2/checks/'}${action.payload.id}/`;
    const response: AxiosResponse<TAmlChecksDetails> = yield call(request.get, url);

    const addressExposure = [...response.data?.address_exposure?.exposure || []].sort((a, b) => b.share - a.share );
    const indirectExposure = [...response.data?.transfer_exposure?.indirect_exposure || []]
      .sort((a, b) => b.share - a.share );

    yield put(amlChecksDetailsSuccess({
      ...response.data,
      address_exposure: {
        ...response.data?.address_exposure,
        exposure: addressExposure.map(item => {
          const color = getRiskGradientColor(item.risk_score);
          return ({
            ...item,
            color,
            rgb: color ? hexToRgb(color) : undefined
          });
        })
      },
      transfer_exposure: {
        ...response.data?.transfer_exposure,
        indirect_exposure: indirectExposure.map(item => {
          const color = getRiskGradientColor(item.risk_score);
          return ({
            ...item,
            color,
            rgb: color ? hexToRgb(color) : undefined
          });
        })
      },
    }));

    if (response.data.address_exposure_status === ECounterpartyStatus.checking
      || response.data.transfer_exposure_status === EExposureStatus.checking) {
      yield call(delay, 5000);
      yield put(amlChecksDetailsRequest({
        ...action.payload,
        prev_address_exposure_status: response.data.address_exposure_status,
        prev_transfer_exposure_status: response.data.transfer_exposure_status,
      }));
    }
    if ((
      action.payload.prev_transfer_exposure_status === EExposureStatus.checking
        && response.data.transfer_exposure_status === EExposureStatus.checked
    )
      || (
        action.payload.prev_address_exposure_status === ECounterpartyStatus.checking
        && response.data.address_exposure_status === ECounterpartyStatus.checked
      )
    ) {
      yield put(amlChecksRisksRequest({
        id: action.payload.id
      }));
    }

  } catch (error) {
    const router = yield getContext('router');
    if ((error as AxiosError)?.response?.status === 404) {
      router.back();
    }
    yield put(amlChecksDetailsFailure(error));
  }
}

function* Saga(): Generator {
  yield all([
    takeLatest(amlChecksRequest.type, amlChecks),
    takeLatest(amlChecksCheckTransferRequest.type, amlChecksCheckTransfer),
    takeLatest(amlChecksCheckAddressRequest.type, amlChecksCheckAddress),
    takeLatest(amlChecksRisksRequest.type, amlChecksRisks),
    takeLatest(amlChecksRecheckRequest.type, amlChecksRecheck),
    takeLatest(getAmlGroupsRequest.type, getAmlGroups),
    takeLatest(amlChecksDetailsRequest.type, amlChecksDetails),
    takeLatest(networksRequest.type, networks),
    takeLatest(amlChecksDetailDeleteRequest, amlChecksDetailDeleteSaga),
    takeLatest(explorerFindAddressOrTransactionRequest.type, explorerFindAddressOrTransaction),
  ]);
}

export default Saga;
