import { FC } from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";

import { EAssetsTypes } from "config/types";
import { maxMobileMediaWidth } from "config/constants";
import { getAssetSingleId } from "config/paths";

import BNDropdown from "components/BNDropdown";
import EllipsisHorizontalIcon from "components/Icons/EllipsisHorizontalIcon";
import { TBNDropdownChildren } from "components/BNDropdown/types";

import {
  getSingleAssetDataSelector,
} from "store/assets/selectors";

import { TAssetsSingleMenuDropdownProps } from "../../types";

import AssetSingleMenuSynchronize from "../AssetSingleMenuSynchronize";
import AssetSingleMenuRename from "../AssetSingleMenuRename";
import AssetSingleMenuSyncOptions from "../AssetSingleMenuSyncOptions";
import AssetSingleMenuDelete from "../AssetSingleMenuDelete";
import AssetSingleMenuCopyAddress from "../AssetSingleMenuCopyAddress";
import AssetSingleMenuShare from "../AssetSingleMenuShare";
import AssetSingleMenuPublic from "../AssetSingleMenuPublic";

const AssetsSingleMenuDropdown: FC<TAssetsSingleMenuDropdownProps> = ({
  setVisibleTrue
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });
  const id = getAssetSingleId();
  const data = useSelector(getSingleAssetDataSelector(id));

  const overlay = ({ setActive }: TBNDropdownChildren) => (
    <>
      {isMobile && (
        <>
          <AssetSingleMenuCopyAddress key="1" setActive={setActive}/>
          <AssetSingleMenuSynchronize key="2" setActive={setActive}/>
          <AssetSingleMenuShare id={id as string}/>
          <AssetSingleMenuPublic setActive={setActive} id={id as string}/>
        </>
      )}
      <AssetSingleMenuRename key="3" setActive={setActive} setVisibleTrue={setVisibleTrue}/>
      {data?.stock_type === EAssetsTypes.EXCHANGE && (
        <AssetSingleMenuSyncOptions key="4" setActive={setActive} setVisibleTrue={setVisibleTrue} />
      )}
      <AssetSingleMenuDelete key="5" setActive={setActive} setVisibleTrue={setVisibleTrue} />
    </>
  );

  return (
    <BNDropdown
      overlay={overlay}
      className="other-navigation assets-single__header_menu"
    >
      {() => (<EllipsisHorizontalIcon />)}
    </BNDropdown>
  );
};

export default AssetsSingleMenuDropdown;
