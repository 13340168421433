import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getAMLSingleId, getAMLSinglePath, paths } from "config/paths";
import { LinkIds } from "config/constants";
import { EQueries } from "config/types";

import EqualizerIcon from "components/Icons/EqualizerIcon";
import ClarityArchiveLineIcon from "components/Icons/ClarityArchiveLineIcon";

import useQuery from "hooks/useQuery";

import { EAmlSingleIds } from "store/aml/types";
import { getMyAssetsSelector } from "store/assets/selectors";
import { amlStateSelector } from "store/aml/selectors";
import { amlStateRequest } from "store/aml/reducers";

import SidebarCollapse, { ECollapseKeys, TSidebarCollapseProps } from "../SidebarCollapse";

const SidebarAMLRisk: FC<Pick<TSidebarCollapseProps, "title">> = ({ title }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const { data } = useSelector(getMyAssetsSelector);
  const { data: amlState, fetching } = useSelector(amlStateSelector);

  const amlSingleId = getAMLSingleId();
  const isAml = amlSingleId === EAmlSingleIds.aml;
  const isArchived = amlSingleId === EAmlSingleIds.archived;

  const onClickAmlChecks = () => {
    if (isAml) {
      history.push(
        paths.AML
      );
    } else {
      history.push(
        getAMLSinglePath(EAmlSingleIds.aml)
      );
    }
  };

  const onClickArchive = () => {
    if (isArchived) {
      history.push(
        paths.AML
      );
    } else {
      history.push(
        getAMLSinglePath(EAmlSingleIds.archived)
      );
    }
  };

  useEffect(() => {
    if (!fetching) {
      dispatch(amlStateRequest({}));
    }
  }, []);
  return (
    <SidebarCollapse
      title={title}
      assets={data?.filter(item => item.risk_score || item.risk_score === 0) || []}
      collapseKey={ECollapseKeys.amlRisks}
      path={paths.AML}
      showRiskScore={true}
      showModalCallback={() => {
        query.append(EQueries.isAml, String(true));
        history.replace({ search: query.toString() });
      }}
      disableOpenModal={true}
      id={LinkIds.AML}
      showPlusIcon={true}
    >
      <div className="sidebar-aml__additional">
        <div
          className={cn("sidebar-aml__aml-checks", { active: isAml })}
          onClick={onClickAmlChecks}
        >
          <EqualizerIcon/>
          <span className="title">{t("amlPage.amlChecks")}</span>
        </div>
        {!!amlState?.archived_checks && <div
          className={cn("sidebar-aml__archive", { active: isArchived })}
          onClick={onClickArchive}
        >
          <ClarityArchiveLineIcon/>
          <span className="title">{t("amlPage.archived")}</span>
        </div>}
      </div>
    </SidebarCollapse>
  );
};

export default SidebarAMLRisk;
